.version-link {
  margin-top: 1rem;
  font-size: 1rem;
  text-align: right;
  font-style: italic;
  float: right;
  
  p {
    margin: 0;
    padding: 0;
  }
  
  .version-link-main {
    color: #3679a1;
    font-weight: 700;
    margin-bottom: 0.8rem;
    
    &:hover {
      color: #16425b;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  
  .version-link-detail {
    margin-top: -0.8rem;
    font-size: 0.8rem;
  }
}
