$bg-color: #f5e7a8;
$border-color: #e4ca53;

.tooltip {

    font-size: 12px;
    padding-top: 7px;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 3px;
    z-index: 1000;

    [class*="fa-"] {
        font-size: inherit;
    }

    .tooltip-inner {
        border: solid 1px $border-color;
        border-radius: 3px;
        background-color: $bg-color;
        max-width: 225px;
    }

    .tooltip-arrow {
        &,
        &::after {
            position: absolute;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            top: 0;
            left: 50%;
            margin-left: -5px;
            border-width: 0 8px 8px;
            border-bottom-color: $border-color;
        }

        &::after {
            content: '';
            margin-left: -8px;
            top: 2px;
            border-bottom-color: $bg-color;
        }
    
    }

    i {
        color: #67590e;
    }
        
      
}