.navbar {
    &--sub-navigation {
        .non-link-item {
            font-family: "Roboto Condensed", sans-serif;
            display: inline-block;
            padding: 7px 42px 8px 25px;
            text-align: center;
            font-size: 17px;
            text-transform: uppercase;
            color: $blue;
            font-weight: 700;

            @include respond(lg) {
                padding: 10px 15px 9px;
            }

        }
       
        .navbar-collapse.show .non-link-item {
            padding-right: 8px;
        }
    }
}

.navbar-collapse.show {
    small {
        font-size: inherit;
    }
}

.non-link-item {
    display: flex !important;
    -webkit-appearance: none;
    border: none;
    background: transparent;

    &__inner {
        @include respond(lg) {
            display: flex;
            flex-direction: column;
        }
    }

    &.sub-navigation__toggle {
        width: auto;
        position: static;
        padding-top: 10px !important;

        &::after {
            width: 30px;
            @include respond(lg) {
                padding-top: 11px;
                left: -9px;
            }
        }
    }

    &:hover,
    &:focus,
    &:active {
        .non-link-item__inner {
            color: $blue-1;
            text-decoration: underline;
        }
    }
}