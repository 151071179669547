@mixin respond($breakpoint) {
	
	@if $breakpoint == sm {
		@media only screen and (min-width: 576px) {
			@content;
		}
	}
	
	@if $breakpoint == md {
		@media only screen and (min-width: 768px) {
			@content;
		}
	}
	
	@if $breakpoint == lg {
		@media only screen and (min-width: 992px) {
			@content;
		}
	}

	@if $breakpoint == xl {
		@media only screen and (min-width: 1200px) {
			@content;
		}
	}
}

@mixin respond-down($breakpoint) {
	
	@if $breakpoint == sm {
		@media only screen and (max-width: 575.999px) {
			@content;
		}
	}
	
	@if $breakpoint == md {
		@media only screen and (max-width: 767.999px) {
			@content;
		}
	}
	
	@if $breakpoint == lg {
		@media only screen and (max-width: 991.999px) {
			@content;
		}
	}

	@if $breakpoint == xl {
		@media only screen and (max-width: 1199.999px) {
			@content;
		}
	}
	
}

// below adapted from https://dev.to/inverseswirl/how-to-add-keyframes-animation-code-in-scss-46k8
@mixin keyframes($name) {
    @keyframes #{$name} {
        @content;
    }
}
