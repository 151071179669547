.value-definition {
    border-bottom: 1px solid $gray-400;
}

.complex-value-definition {
    border: 1px solid $gray-400;
    padding: 10px 10px 15px 10px;
    // margin-left: 20px;

    &__var {
        position: relative;
        background: rgba($white,.5);
        margin-bottom: 15px;
        padding: 20px 50px 20px 20px;
    }

    &__delete-group {
        position: absolute;
        top: 10px;
        right: 10px;
    }   
}