/*------------------------------------*\
    $VARIABLES
\*------------------------------------*/

//Colors
$white: #fff;
$black: #000;

//Blues
$blue: #3679a1;
$blue-1: #16425b;
$blue-2: #f8fbfd;
$blue-3: #ddebf4;
$blue-4: #4c7a90;
$blue-5: #77c1ea;

//Oranges
$orange: #f38130;
$orange-1: #a94c0a;
$orange-2: #c1570b;
$orange-3: #fdece0;

//Yellows
$yellow: #c6ac1a;

//Grays
$gray-300: #c7d1d6;
$gray-400: #b1b3b3;
$gray-500: #84878a;
$gray-600: #5c6266;
$gray-700: #404040;
$gray-800: #222526;

//Greens
$green: #408c3b;
$green-1: #346232;

//Reds
$red: #c43737;
$red-1: #a72323;

$secondary: $orange;
$info: $blue-4;
$light: $gray-300;
$dark: $gray-700;


//Typography
$font-family-sans-serif : 'Open Sans', sans-serif;
$font-secondary : 'Roboto', sans-serif;
$font-tertiary : 'Roboto Condensed', sans-serif;

$line-height-base: 1.4;

$body-color: $gray-700;

$link-hover-color: $blue-1;