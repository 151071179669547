.section {
    &--blue {
        background: $blue-3;
        padding: 6px 22px 22px 22px;
    }

    &--gray {
        padding: 6px 22px 22px 22px;
        background: rgba(#c7d1d6, .5);
    }
}