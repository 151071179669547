input.number-input {
    width: 100px;
}

select.form-control {
    min-width: 100px;
    
    &:disabled {
        &:hover {
            cursor: default;
            color: $gray-700 !important;
            border-color: $gray-400 !important;
            background-color: #e9ecef !important;
        }
    }
    
    &.hide-arrow {
        &:disabled {
            background-image: none;
        }
    }
}

textarea {
    line-height: 1.8em !important;
    padding: .5em 1em !important;
}

td {
    min-width: fit-content !important;
}