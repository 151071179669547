.border-0 {
    border-width: 0px !important;
}

.border-1 {
    border-width: 1px !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}