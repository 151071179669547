table, 
.table {

    .btn--icon {

        [class*="fa-"] {
            margin: 0;
        }

    }

    th {
        .btn {
            color: #7898a9;

            &:hover,
            &:focus {
                color: $blue-5;
            }
        }
    }
}

.table-scroll {
    position: static; // this keeps tooltip from getting cut off by overflow: hidden
}

.table-scroll--sticky {
    
    &__wrapper {
        margin-right: 112px;
    }

    tr {
        min-height: 61px;
    }

    .sticky-cell  {
        position: absolute;
        right: 0;
        // z-index: 1;

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0px;
            bottom: -1px;
            left: -5px;
            width: 7px;
            background: linear-gradient(to left, #9db6c2 0, #9db6c2 1px, rgba($black, .13) 1px, rgba($black, 0));
            // left: 0;
            // width: 2px;
            // background: #9db6c2;

        }

    }
    
    th {
      white-space: nowrap;
    }

    th.sticky-cell {
        border-left: 0;

        &::after {
            top: -4px;
            bottom: -4px;
            left: -4px;
            background:  linear-gradient(to left, #2c536a 0, #2c536a 2px, rgba($black, .3) 2px, rgba($black, 0));
        }
    }

    td.sticky-cell {
        
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 1px;
            bottom: 0;
            left: 0;
            right: 0;
            background: #f8fbfd;
        }
    }

    tr:first-child td.sticky-cell  {
        // together, these styles make sure bottom border doesn't vanish
        border-top: 1px solid transparent;
        margin-top: -1px; 
    }

}

.table, table {
    thead th .btn-secondary {
        position: static;
        transform: none;
    }

    th, 
    & {
        .table__blank-cell {
            background: $white;
            border-color: $white $blue-1 transparent transparent;
            
            &--has-sibling {
                border-color: #fff #fff transparent transparent;
            }
        }

    }
}

.table--row-headers {
    tbody {
        tr {
            th {
                border-bottom: 3px solid rgba(#FFF, .1);
                border-right: 3px solid $blue-1;
                border-top: 3px solid #2c536a;
                border-left: 3px solid $blue-1;
            }
        }
    }
}

th.span-header,
th .span-header {
    color: $blue-5;
    font-weight: 700;
    text-align: center;
    

    &--row {
        transform: rotate(180deg);
        writing-mode: vertical-rl;
        border-right: 3px solid #2c536a !important;
        border-left-width: 0px !important;
        vertical-align: middle;
    }

    &--col {
        border-bottom-color: #2c536a !important;
    }
}

.table--variable-details {

    > thead {
        th {
            font-size: 25px;
            font-weight: 700;
            position: relative;
            border-top: none;
            min-width: 260px;
            background-clip: padding-box;

            .btn {
                padding: 0;
            }
        }
    }

    td {
        text-align: left;
    }

}

.table--diver-download {
    background-color: $white;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 4px;

    thead {
        th {
            border: 1px solid $blue-1;
            border-right: 1px solid #4c536a;
            border-left-width: 0;
            font-weight: 700;
            text-align: center;
            font-size: 18px;

            a.btn {
                color: #7898a9;

                &:hover,
                &:focus {
                    color: $blue-5;
                }
            }
        }
    }

    .table {
        &__blank-cell {
            border: 1px solid $white;
        }
    }

    tbody {
        th {
            background: $blue-3;
            border: 1px solid $blue-4;
            font-weight: 400;
            color: $gray-700;
        }
    }

    td {
        text-align: center;
        border: 1px solid $blue-4;
        border-left-width: 0;
       
        &:last-child {
            border-right: 1px solid $blue-4;
        }


        @for $i from 0 through 100 {
            &.td--heat-value-#{$i} {
                background: 
                    rgba($orange, $i * .01);
                    // $blue-2;   
            }
        }
    }
}

.downcode-table {
    th:first-of-type {
        min-width: 400px;
    }
    th:nth-of-type(2) {
        min-width: 200px;
    }
}
.embed-table {
  table-layout: fixed;
  width: 100%;
}

.construct-map-table {
  tr:first-child, th:first-child {
    width: 25px;
  }
}
