.inner-page {
    position: relative;
    isolation: isolate;

    &__header {

        display: flex;
        align-items: center;
        top: 40px;

        margin-bottom: 28px; 

        h2 {
            margin-bottom: 0;
        }
        
        &--sticky {
            background: $white;
            position: sticky;
            top: 0px;
            padding-top: 10px;
            padding-bottom: 10px;
            z-index: 1002;
            margin-top: -20px;
            min-height: 69px;

            // &::before {
            //     content: '';
            //     position: absolute;
            //     height: 10px;
            //     left: 0;
            //     right: 0;
            //     top: 100%;
            //     background: linear-gradient(to bottom, rgba($blue-1, .2), rgba($blue-5, 0));
            //     z-index: 1000;
            // }

            // h2, .inner-page__actions {
            //     z-index: 1003;
            // }
        }
    }

    &__actions {
        margin-left: auto;
    }


    &__shadow {
        position: sticky;
        height: 10px;
        width: 100vw;
        top: 97px;
        background: linear-gradient(to bottom, rgba($blue-1, .2), rgba($blue-5, 0));
        z-index: 1000;
        transform: translateY(-28px);
        margin-left: calc(-1 * (100vw - 100%) / 2);
    }
    
    &__cover {
        height: 10px;
        width: 100vw;
        background-color: $white;
        margin-top: -38px;
        z-index: 1001;
        left: 50%;
        transform: translateX(-50%);
        position: relative;
    }
}
