.btn {
    &.btn--icon {
        color: $blue-1;
    }
}
.btn--icon {
    background: transparent;
    
    &:not(.disabled) {
        &:hover,
        &:focus,
        &:active {
            background: transparent;
            color: $blue;
        }

        &:not(.btn-secondary):not(.btn--outline) {
            &:focus {
                box-shadow: none;
            }
        }
    }

    &.btn-secondary {
        color: $white;
        padding: 14px;
    }
}

.btn-md {
    font-size: 15px;
    padding: 1em;
}

.btn--caps {
    text-transform: uppercase;
}

.btn--icon.btn--outline {
    border-width: 1px;
    color: $blue;
}

.icon-btn-group {
    display: flex;
    align-items: center;
}

.btn--text-link {
    padding: 0;
    font-weight: 400;
    background-color: transparent;
    color: $blue;

    &:hover,
    &:focus,
    &:active {
        color: $blue-1;
        text-decoration: underline;
    }
}

.btn-secondary.disabled {
    border: 1px solid $gray-400 !important;
    background-color: transparent !important;
    color: #c7d1d6 !important;
    cursor: default !important;
    opacity: 1 !important;
}
.btn-secondary:not(.page-link):not(.disabled) {
    border: 1px solid $orange !important;
}

.pagination button.page-link.btn {
    padding: 6px 4px;
}

.btn.disabled {
    cursor: default;
}

// adapted from https://dev.to/dcodeyt/create-a-button-with-a-loading-spinner-in-html-css-1c0h
.btn.btn--loading {
    position: relative;
    & .btn--text {
        visibility: hidden;
        opacity: 0;
    }
    &::after {
        content: "";
        position: absolute;
        width: 2rem;
        height: 2rem;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 4px solid transparent;
        border-top-color: #ffffff;
        border-radius: 50%;
        
        @include keyframes(button-loading-spinner) {
            from {
                transform: rotate(0turn);
            }

            to {
                transform: rotate(1turn);
            }
        }
        animation: button-loading-spinner 1s ease infinite;
    }
}

