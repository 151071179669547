@use "sass:math";
$cohort-height: 68px;
$cohort-offset-height: math.div($cohort-height, 2);


$cohort-connector-color: #d0d1d1;
$cohort-content-margin: 5px;

%cohort-group-top-connector {
    content: '';
    border-left: 1px solid $cohort-connector-color;
    margin-top: $cohort-offset-height + $cohort-content-margin;
    margin-left: -1px;
    flex-shrink: 0;
}

.cohorts {
    padding-left: 30px;

    &--relate {
        padding-left: 65px;

        .cohort__relate {
            display: inline-block;
            margin: $cohort-content-margin 0 $cohort-content-margin #{$cohort-height * -1};
            width: auto;
        }

        .cohort:first-child {
            border-left: 0;

            &::before {
                @extend %cohort-group-top-connector;
                height: calc(100% - #{$cohort-offset-height - $cohort-content-margin});
                position: absolute;
                z-index: -1;
            }

            .cohort__main {

                &::before {
                    border-left: 0;
                }
            }
        }
    }
}

.cohort {

    position: relative;
    flex-grow: 1;

    &__content,
    &__relationship {
        position: relative;
        margin: $cohort-content-margin 0;
        flex-grow: 1;
        border: 1px solid $blue-4;        
        background: $blue-3;
    }

    &__main {
        width: 100%;
        align-items: start;

        &--pedigree + .cohorts {
            padding-left: 102px;
        }
    }

    &__visible {
        position:relative;
        display: flex;
        align-items: center;
        min-height: #{$cohort-height - $cohort-content-margin * 2};
    }

    &__visible,
    &__description {
        padding: 10px;
    }


    &__description {
        border-top: 1px solid $blue-4;
        transition: all .25s, border-top 0s .1s;
        background: $blue-2;
        
        &--collapsed {
            height: 0;
            padding-top: 0;
            padding-bottom: 0;
            overflow: hidden;
            border-top-width: 0px;

        }
    }

    &__relationship {
        height: 62px;
        width: 62px;
        margin: 5px 10px;
        flex-grow: 0;
        min-height: $cohort-height;
        color: $blue-1;

        & .cohort__visible {
            justify-content: center;
            height: 100%;
        }

        &::before, 
        &::after {
            content: '';
            height: 1px;
            background: $cohort-connector-color;
            width: 12px;
            display: block;
            position: absolute;
            top: $cohort-offset-height - 2; // -2 for border?
            z-index: -1;
        }

        &::before {
            right: 100%;
        }

        &::after {
            left: 100%;
        }
    }

    &--indent {

        border-left: $cohort-connector-color solid 1px;
       
        .cohort__main {
            display: flex;

            &::before {
                content: '';
                height: #{$cohort-offset-height + $cohort-content-margin * 2};
                width: 31px;
                position: relative;
                border-bottom: $cohort-connector-color solid 1px;
                border-left: $cohort-connector-color solid 1px;
                margin-left: -1px;
                margin-top: $cohort-content-margin * -1;
                flex-shrink: 0;
            }

        }

        &:last-child {
            border-left-color: transparent; 
        }
    }

    &__parents {
        display: flex;

        & .cohort {
            flex: 1 1 50%;
        }
        
        & .cohort:first-child {

            display: flex;

            &::before {
                @extend %cohort-group-top-connector;
                height: 100%;
            }

            .cohort__main {

                &::before {
                    border-left: 0;
                }
            }
        }

        & .cohort:last-child:not(:first-child) {
            .cohort__main {

                &::before {
                    display: none;
                }
            }
        }
        
    }

    &--empty {
        .cohort__content {
            background: $blue-2;
        }
    }

    &__arrow {
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        transform: translateY(1px);
        background-image: linear-gradient(to bottom, rgba($blue, .45), $blue-2);
        height: 60px;
    }

}