.linked-card {

    border: $gray-400 solid 1px;
    border-radius: 3px;
    padding: 32px 28px;
    display: flex;
    flex-direction: column;
    background: $white;

    &__title {
        
        font-size: 24px;
        color: $blue-1;
        font-weight: 700;
        margin-bottom: 10px; 

    }

    &__text {

        font-size: 14px;
        color: #787878;
        line-height: 1.429;   
        letter-spacing: .02em;  
        margin-bottom: 24px;    

    }

    &__cta {

        font-size: 16px;
        font-family: $font-secondary;
        color: $orange-2;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1;
        margin-top: auto;
        
    }

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        background: rgba($orange, .15);
        border-color: $orange-2;

        .linked-card__title {
            color: $orange-2;
        }
    }
}