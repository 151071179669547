.variable {
    position: relative;
    padding: 18px;
    border: solid $blue-4;
    border-width: 1px 0 1px 0;

    &__bg {
        position: absolute;
        top: 2px;
        right: 0;
        bottom: 2px;
        left: 0;
        background: rgba($blue-2, .7);
    }

    &__content {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__name {
        width: 210px;
        padding-right: 15px;
        flex-shrink: 0;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__question {
        font-size: 14px;
    }
 
}